<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Local Name of Designations</div>
        <div class="col-lg-6 col-12 text-end d-flex justify-content-end custom-flex-cloumn-mob">
            <button type="button" class="header-btn-outer btn btn-primary" @click="addCustomRoleModalOpen()">
                Add Local Name of Designation
            </button>
        </div>
    </div>
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
        <DataTable :value="customDesignationList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
            :paginator="true" :rows="30" :lazy="true" :totalRecords="totalRecords" @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading" dataKey="aw1" class="p-datatable-sm">
            <template v-if="!loading" #empty>No records found.</template>
            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
            <Column field="CustomName" header="Local Name of Designations" headerStyle="width: 40%" bodyStyle="width: 40%">
                <template #body="{ data }">
                    <div class="text-capitalize">
                        {{ data.aw3 ? data.aw3 : "N/A" }}
                    </div>
                </template>
            </Column>
            <Column field="Name" header="Main Designations" headerStyle="width: 40%" bodyStyle="width: 40%">
                <template #body="{ data }">
                    <div class="text-capitalize">
                        {{ data.aw5 ? data.aw5 : "N/A" }}
                    </div>
                </template>
            </Column>
            <Column field="status" header="Status" class="justify-content-center" headerStyle="width: 20%"
                bodyStyle="width: 20%">
                <template #body="{ data }">
                    <div>
                        <InputSwitch v-model="data.aw4" :true-value="dynamicTrueActiveValue"
                            :false-value="dynamicFalseInactiveValue" @change="confirmModalOpen(data)" />
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- Add Custom Designation Start Here -->
    <div class="modal-mask" v-if="addCustomRoleDialog">
        <div class="modal-dialog modal-sm modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Add Designation</h5>
                    <button type="button" class="btn-close" @click="addCustomRoleModalClose()"></button>
                </div>
                <div class="modal-body modal-body-scroll-outer">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Select Main Designation<span class="text-danger">*</span></label>
                                <Multiselect v-model="add.designation" :options="designationList" :searchable="true"
                                    label="label" placeholder="Select Main Designation" class="multiselect-custom"
                                    :canClear="true" :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                                <div class="custom-error" v-if="v$.add.designation.$error">
                                    {{ v$.add.designation.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Local Name of Designation<span class="text-danger">*</span></label>
                                <input type="text" :searchable="true" label="label" v-model="add.customdesignation" class="form-control text-capitalize"
                                    id="formLastNameInput" @keypress="isLetterWithSpace($event)"
                                    placeholder="Enter Local Name of Designation" autocomplete="off" maxlength="50"
                                    required />
                                <div class="custom-error" v-if="v$.add.customdesignation.$error">
                                    {{ v$.add.customdesignation.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-lg-12 col-md-12 col-12 text-end">
                        <button type="button" class="btn user-role-btn" @click="adddesignationbtn()" :disabled="btnloader">
                            <span v-if="!btnloader">Submit</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="btnloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Custom Designation End Here -->
        <!-- confirmation modal start here -->
        <div class="modal-mask" v-if="confirm_popup_status">
        <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
            <div class="modal-content text-center">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="confirm-dialog-header">Confirmation</div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                                </div>
                                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                                    Are you sure you want to proceed?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-end">
                        <button type="button" :disabled="showconfirmloaderbtn" class="btn confirm-modal-no-btn" @click="confirmModalClose()">
                            No
                        </button>
                        <button type="button" class="btn confirm-modal-yes-btn" @click="changeDesignationStatus()"
                            :disabled="showconfirmloaderbtn">
                            <span v-if="!showconfirmloaderbtn">Yes</span>

                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="showconfirmloaderbtn">
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- confirmation modal end here -->
</template>
<script>
import ApiService from "../../service/ApiService";
import { required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
export default {
    data() {
        return {
            v$: useValidate(),
            designationList: [],
            btnloader: false,
            customDesignationList: null,
            totalRecords: 0,
            loading: false,
            page_no: 0,
            modalbtnloader: false,
            addCustomRoleDialog: false,
            roleid: "",
            add: {
                customdesignation: '',
                designation: "",
            },
            dynamicTrueActiveValue: 1,
            dynamicFalseInactiveValue: 0,
            confirm_popup_status: false,
            showconfirmloaderbtn: false,
            confirm_designation_id: '',
            confirm_status_value: '',
        };
    },
    validations() {
        return {
            add: {
                designation: {
                    required: helpers.withMessage("Please select main designation", required),
                },
                customdesignation: {
                    required: helpers.withMessage("Please enter local name of designation", required),
                },
            },
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.showmodalloader = true;
        // this.getOrignalUserDesignations();
        this.userDesignationsList();
    },
    methods: {
        getOrignalUserDesignations(e) {
            this.ApiService.getOrignalUserDesignations(e).then((data) => {
                if (data.status == 200) {
                    this.designationList = data.data;
                }
            });
        },
        userDesignationsList(e) {
            this.loading = true;
            this.ApiService.userDesignationsList(e).then((data) => {
                if (data.success == true) {
                    this.customDesignationList = data.data;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.customDesignationList = null;
                    this.totalRecords = 0;
                    this.loading = false;
                }
            });
        },
        adddesignationbtn() {
            this.v$.add.$validate();
            let fields = {};
            fields["aw2"] = this.add.designation.value;
            fields["aw5"] = this.add.designation.label;
            fields["sequence"] = this.add.designation.sequence;
            fields["aw3"] = this.add.customdesignation;
            fields["aw4"] = 1;
            if (!this.v$.add.$error) {
                this.btnloader = true;
                this.ApiService.adddesignation(fields).then((items) => {
                    if (items.success == true) {
                        this.$toast.open({
                            message: items.message,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
              this.v$.$reset();
            }, 0);
                        this.btnloader = false;
                        this.add.designation = '';
                        this.add.customdesignation = '';
                        this.addCustomRoleDialog = false;
                        this.userDesignationsList();
                        this.getOrignalUserDesignations();
                    }
                    else {
                        this.$toast.open({
                            message: items.message,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.btnloader = false;
                    }
                });
            }
        },
        addCustomRoleModalOpen() {
            this.addCustomRoleDialog = true;
            this.getOrignalUserDesignations();
            this.add.customdesignation = '';
            this.add.designation = '';
        },
        addCustomRoleModalClose() {
            this.addCustomRoleDialog = false;
            this.add.customdesignation = '';
            this.add.designation = '';
        },
        isLetterWithSpace(e) {
            let charspace = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z\s]+$/.test(charspace)) return true;
            else e.preventDefault();
        },
        changePage(event) {
            this.page_no = event.page;
            this.userDesignationsList({ page_no: this.page_no });
        },
        confirmModalOpen(e) {
            this.confirm_designation_id = e.aw1;
            this.confirm_status_value = e.aw4;
            this.confirm_popup_status = true;
            
        },

        confirmModalClose() {
            this.confirm_popup_status = false;
            this.confirm_designation_id = '';
            this.confirm_status_value = '';
            this.userDesignationsList();
        },
        changeDesignationStatus() {
            let fields = {};
            fields["aw1"] = this.confirm_designation_id;
            if (this.confirm_status_value == 0 || this.confirm_status_value == false) {
                fields["aw4"] = 0;
            }
            if (this.confirm_status_value == 1 || this.confirm_status_value == true) {
                fields["aw4"] = 1;
            }
            this.showconfirmloaderbtn = true;
            this.ApiService.updateDesignationStatus(fields).then((items) => {
                if (items.status === 200) {
                    var successMsg = items.message;
                    this.$toast.open({
                        message: successMsg,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.showconfirmloaderbtn = false;
                    this.userDesignationsList();
                    this.confirm_designation_id = '';
                    this.confirm_status_value = '';
                    this.confirm_popup_status = false;
                } else {
                    this.showconfirmloaderbtn = false;
                    var errorMsg = items.message;
                    this.$toast.open({
                        message: errorMsg,
                        type: "error",
                        duration: 3000,
                        position: "top-right",
                    });
                }
            });
        },
    },
};
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}
.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}
.btn.user-role-btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
    min-width: 80px;
}

.user-role-btn:focus {
    box-shadow: none;
}

.modal-body-fixed-height {
    min-height: 260px;
}
</style>